@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

body {
	background-color: #f7f9fd;
	font-family: Montserrat;
}

.rich-text-editor-container .tox-editor-container {
	border: 1px solid black;
}
#OtpInput[type='number']::-webkit-inner-spin-button,
.OtpInput[type='number']::-webkit-inner-spin-button,
#pdf-controls {
	display: none !important;
}

#pdf-page-info {
	display: none !important;
}

.animate-copy-clipboard {
	animation-name: copyToClipboard;
	animation-duration: 200ms;
	animation-iteration-count: 1;
	transform: translateY(-2px) rotate(10deg);
}

@keyframes copyToClipboard {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: translateY(-2px) rotate(10deg);
	}
}

#OtpInput[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield;
	font-weight: 600;
}

body.modal-open {
	overflow: hidden;
}
.shadow {
	box-shadow: 2px 2px 18px 0px rgba(0, 0, 0, 0.08);
}
.box-shadow {
	box-shadow: 3px 4px 28px 0px rgba(0, 0, 0, 0.08);
}
#react-select-3-input::after {
	outline: none !important;
}

.hero-bg {
	background-image: url(../src/assets/images/hero-bg.png);
	background-repeat: no-repeat;
	background-size: auto;
}
@media only screen and (max-width: 400px) {
	.hero-bg {
		background-size: cover;
	}
}

.bannerImg1 {
	animation: scale 2s ease;
}

@keyframes scale {
	0% {
		transform: scale(120%);
	}

	100% {
		transform: scale(100%);
	}
}

.outlined-number {
	-webkit-text-stroke: 1px #dee3e8;
	/* opacity: 0.2; */
	font-family: 'SF Pro Display';
	color: transparent;
	font-size: 96px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.96px;
	z-index: -100;
}

.box {
	width: full;
	height: full;
	background-color: #ffffff;
	position: relative;
	transition: transform 0.3s, z-index 0.3s;
}

.box:hover {
	transform: translate(20px, -20px);
	z-index: 2;
}

.css-1p3m7a8-multiValue {
	background: #e8f5fc !important;
}

.publicJobApplication .tox-tinymce {
	border: none !important;
}

.publicJobApplication .tox .tox-statusbar {
	align-items: center;
	border: none !important;
	color: rgba(34, 47, 62, 0.7);
	display: flex;
	flex: 0 0 auto;
	font-size: 14px;
	font-weight: 400;
	height: 0px !important;
	overflow: hidden;
	padding: 0 !important;
	position: relative;
	text-transform: none;
}

.editorParent .tox .tox-statusbar {
	border-top: none;
}

.opps {
	position: fixed;
	top: 43%;
	left: 36%;
	transform: translateX(-50%);
	transform: translateY(-50%);
	animation: fromTop 2s alternate ease-out;
}

@keyframes fromTop {
	/* from {
		top: -100px;
	}
	to {
		top: 50%;
		left: 40%;
		transform: -translateX(50%);
		transform: -translateY(50%);
	} */
	0%{
		top: -100px;
	}
	20%,
	50%,
	80%,
	100% {
		top: 43%;
		left: 36%;
		transform: -translateX(50%);
		transform: -translateY(50%);
	}
	40% {
		top: 30%;
	}
	60% {
		top: 35%;
	}
}

.exc {
	color: #91a8fb;
	writing-mode: vertical-lr;
	animation: bounce 1s alternate;
	animation-delay: 2s;
}

@keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
		writing-mode: vertical-lr;
		text-orientation: upright;
	}
	40% {
		transform: translateY(-50px); /* Bounce up */
	}
	60% {
		transform: translateY(-25px); /* Bounce down */
	}
}

.errorTxt{
	animation: topToCenter 4s alternate;
}

@keyframes topToCenter {
	from {
		bottom: -1000px;
	}
	to {
		bottom: 47%;
		left: 50%;
		transform: -translateX(50%);
		transform: -translateY(50%);
		display: block;
	}
}

.prePageBtn{
	animation: topToCenter2 6s alternate;
}

@keyframes topToCenter2 {
	from {
		height: fit-content;
		bottom: -4000px;
	}
	to {
		bottom: 36%;
		left: 50%;
		transform: -translateX(50%);
		transform: -translateY(50%);
		display: block;
	}
}

/* React-quill */
.ql-editor{
  /* min-height: 350px !important; */
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.ql-container.ql-snow {
  border-radius: 16px;
  /* font-size: 16px; */
}

.ql-editor{
  padding: 12px;
  overflow: hidden;
}
@media (min-width: 500px) {
  .ql-editor {
      padding: 40px;
  }
  .ql-container.ql-snow {
    border-radius: 16px;
    font-size: 16px;
  }
}

.collapseNavigation{
  transform: rotateX(40deg);
}

.publicJobApplication{
  position: absolute;
  width: 100%;
  left: 0;
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
